import { FormsFieldPreset, FIELD_COMPONENT_TYPES, FormPlugin, FieldCollectionType, BASE_DESIGN_GROUPS, FieldRenderConfigType } from '@wix/forms-common'
import { FIELDS } from '../../../../../../constants/roles'
import { iconNames } from '../../icons/types'
import { IGeneralField } from '../../general-field'
import { DEFAULT_CATEGORIES } from '../../../../../../panels/manage-fields-panel/constants/manage-fields-constants'
import { fieldManifest } from '../../field-utils'

export class GeneralUploadButton extends IGeneralField {
  public get customFields() {
    return []
  }

  protected get icon() {
    return iconNames.fileUpload
  }

  public get fieldType(): FieldPreset {
    return FormsFieldPreset.GENERAL_UPLOAD_BUTTON
  }

  public get acceptConditions() {
    return false
  }

  public get acceptOperations() {
    return false
  }

  public get collectionFieldType() {
    return FieldCollectionType.IMAGE
  }

  protected get componentType() {
    return FIELD_COMPONENT_TYPES.FILE_UPLOADER
  }

  public get role() {
    return FIELDS.ROLE_FIELD_FILE_UPLOAD
  }

  protected get skin() {
    return 'wysiwyg.viewer.skins.FileUploaderDefaultSkin'
  }

  protected get responsiveSkin() {
    return 'wysiwyg.viewer.skins.ResponsiveFileUploaderDefaultSkin'
  }

  protected get layout() {
    return {
      width: 240,
      height: 75,
    }
  }

  protected get props() {
    return {
      type: 'FileUploaderProperties',
      isHidden: false,
      buttonAlignment: 'rtl',
      filesAlignment: 'left',
      showPlaceholder: true,
      filesType: 'Image',
      filesSizeLimit: 15.0,
    }
  }

  protected get data(): any {
    return {
      type: 'FileUploader',
      buttonLabel: this.translate('preset.fileUploadButtonLabel'),
      placeholderLabel: this.translate('preset.fileUploadPlaceholderLabel'),
    }
  }

  protected get renderConfig() {
    return {
      [FormPlugin.FORM_BUILDER]: {
        crmSync: FieldRenderConfigType.REMOVE,
        addFieldPanelData: {
          category: DEFAULT_CATEGORIES.upload,
          isPremium: true,
        }
      }
    }
  }

  public get base() {
    return GeneralUploadButton.prototype
  }

  public get manifest() {
    return fieldManifest({ duplicatable: true })
  }

  public get supportsLabel() {
    return true
  }

  public get supportedInResponsive() {
    return true
  }

  public get designMapping() {
    return {
      [this.componentType]: {
        [BASE_DESIGN_GROUPS.INPUT_BACKGROUND]: ['btn_bg'],
        [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR]: ['btn_brd'],
        [BASE_DESIGN_GROUPS.INPUT_CORNERS]: ['btn_rd'],
        [BASE_DESIGN_GROUPS.PLACEHOLDER_TEXT_COLOR]: [
          'btn_txt',
          'btn_txt_h',
          'txt_f',
          'txt_e',
          'txt_d',
          'x_file_txt_e',
          'x_file_txt_f',
          'x_file_txt_h',
          'x_file_txt',
        ],
        [BASE_DESIGN_GROUPS.MAIN_TEXT_FONT]: ['btn_fnt'],
        [BASE_DESIGN_GROUPS.LABEL_TEXT_COLOR]: ['txtlbl'],
        [BASE_DESIGN_GROUPS.LABEL_TEXT_FONT]: ['fntlbl'],
        [BASE_DESIGN_GROUPS.INPUT_BACKGROUND_HOVER]: ['btn_bg_h'],
        [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR_HOVER]: ['btn_brd_h'],
        [BASE_DESIGN_GROUPS.INPUT_BACKGROUND_FOCUS]: ['bg_f'],
        [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR_FOCUS]: ['brd_f'],
        [BASE_DESIGN_GROUPS.INPUT_BACKGROUND_ERROR]: ['bg_e'],
        [BASE_DESIGN_GROUPS.INPUT_BORDER_COLOR_ERROR]: ['brd_e'],
        [BASE_DESIGN_GROUPS.LABEL_REQUIRED_TEXT_COLOR]: ['txtlblrq'],
        [BASE_DESIGN_GROUPS.INPUT_SHADOW]: ['btn_shd'],
      }
    }
  }

  public get layoutComponentProps() {
    return [
      'filesAlignment',
      'labelPadding',
      'labelMargin',
      'buttonAlignment',
    ]
  }

  protected get styleProperties() {
    return {
      'properties': {
        'alpha-brd_e': '0.6',
        'alpha-brd_f': '1',
        'alpha-btn_brd': '0.55',
        'alpha-btn_brd_h': '1',
        'alpha-file_bg_h': '0',
        'bg_d': '#DBDBDB',
        'bg_e': 'color_11',
        'bg_f': 'color_11',
        'boxShadowToggleOn-btn_shd': 'false',
        'brd_d': '#FFFFFF',
        'brd_e': '#f60419',
        'brd_f': 'color_18',
        'brw_d': '0',
        'brw_e': '1',
        'brw_f': '1',
        'btn_bg': '#FFFFFF',
        'btn_bg_h': 'color_11',
        'btn_brd': 'color_15',
        'btn_brd_h': 'color_18',
        'btn_brw': '1',
        'btn_brw_h': '1',
        'btn_fnt': 'font_8',
        'btn_rd': '0px',
        'btn_shd': '0px 1px 4px 0px rgba(0,0,0,0.6)',
        'btn_txt': 'color_15',
        'btn_txt_h': 'color_15',
        'file_bg_h': '#FFFFFF',
        'file_fnt': 'font_9',
        'txt_d': '#FFFFFF',
        'txt_e': 'color_15',
        'txt_f': 'color_15',
        'x_file_txt': 'color_14',
        'x_file_txt_e': 'color_14',
        'x_file_txt_f': 'color_14',
        'x_file_txt_h': 'color_14',
      },
    }
  }
}
